import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import PageFooter from '../components/PageFooter';
import Banner from '../components/Banner';
import Tile from '../components/Tile';

const DisclaimerTitle = 'Disclaimer';
const DisclaimerText = '<h3>Copyright</h3>\n<p>Alle rechten voorbehouden. De gehele inhoud van deze website van Sharewater (teksten, modellen, afbeeldingen, foto’s, ontwerpen en andere zaken) zijn beschermd door copyright en andere beschermende wetten. <br />\nZonder uitdrukkelijk schriftelijke toestemming van Sharewater is het niet toegestaan om delen van deze website te gebruiken in andere websites of voor enige andere toepassing. Deze website kan gegevens bevatten die zijn beschermd door copyright en door andere wet- of regelgeving inzake copyright van derden en deze zijn derhalve dienovereenkomstig beschermd.</p>\n<h3>Verantwoording / aansprakelijkheid</h3>\n<p>Sharewater heeft deze website met zorgvuldigheid ontworpen en samengesteld naar beste kennis en weten. Toegang tot en gebruik van de website en de daarin opgenomen verwijzingen geschieden voor risico van de bezoeker ervan. Claims ten aanzien van schade en garantie veroorzaakt door mogelijk onvolledige of incorrecte gegevens zijn uitgesloten. Sharewater is op geen enkele wijze verantwoordelijk of aansprakelijk voor schade (ook niet voor indirecte- en/of gevolgschade) die kan voortvloeien uit bezoek aan en gebruik van deze website of de daarin aangebrachte verwijzingen.</p>';

const IndexPage = () => (
  <Layout>
    <Banner />
    <section id="main" class="tile">
      <Tile title={DisclaimerTitle} message={DisclaimerText} messageType="disclaimer"/>
      <a href="/">Sluiten</a>
    </section>
    <PageFooter />
  </Layout>
);

export default IndexPage;
